import type { AdDetails } from '../../../@types/adCommon.js';
import { addCsmPlacementTag, addCsmTag } from '@amzn/apejs-instrumentation/src/metrics/csm/tag.js';
import { setCsaEntity } from '@amzn/apejs-instrumentation/src/metrics/csa/events.js';
import { LightAdDetails } from '../../light-ad/inbound/dtos/lightAdDetails.js';
import { Placement } from '../../light-ad/domain/model/placement.js';

export const addLightAdTags = (placement: Placement, lightAdDetails: LightAdDetails) => {
    addCsmPlacementTag('adrender', placement);
    addCsmPlacementTag('adrender:lightad', placement);
    if (lightAdDetails.adCreativeMetaData.adCreativeId) {
        addCsmPlacementTag('adrender', placement, `creativeid:${lightAdDetails.adCreativeMetaData.adCreativeId}`);
        setCsaEntity({ creativeId: lightAdDetails.adCreativeMetaData.adCreativeId.toString() });
    }
    if (lightAdDetails.adCreativeMetaData.adProgramId) {
        addCsmPlacementTag('adrender', placement, `programid:${lightAdDetails.adCreativeMetaData.adProgramId}`);
        setCsaEntity({ programId: lightAdDetails.adCreativeMetaData.adProgramId.toString() });
    }
    if (typeof lightAdDetails.isCardsFlow !== 'undefined') {
        setCsaEntity({ isCardsFlow: lightAdDetails.isCardsFlow.toString() });
    }
    const creativeTemplateName = getCreativeTemplateNameForTagging(lightAdDetails);
    addCsmPlacementTag('adrender', placement, `creativetemplatename:${creativeTemplateName}`);
    setCsaEntity({
        adrender: 'true',
        creativeTemplateName: creativeTemplateName,
        isLightAds: 'true',
    });
};

export const addSafeframeTags = (adDetails: AdDetails) => {
    if (!adDetails?.adCreativeMetaData || !Object.keys(adDetails.adCreativeMetaData).length) {
        addCsmTag('noadrender');
        setCsaEntity({ adrender: 'false' });
        return;
    }

    addCsmTag('adrender');
    addCsmTag('adrender:safeframe');

    tagTemplate(adDetails);
    tagCreativeId(adDetails);
    tagProgramId(adDetails);
    tagIsCardsFlow(adDetails);
};

const tagTemplate = (adDetails: AdDetails): void => {
    const creativeTemplateName = getCreativeTemplateNameForTagging(adDetails);
    addCsmTag('adrender', 'creativetemplatename:' + creativeTemplateName);
    setCsaEntity({
        adrender: 'true',
        creativeTemplateName: creativeTemplateName,
        isLightAds: 'false',
    });
};

/*
 * Helper function to prepare creative template name for tagging
 * 1. Change to lower case
 * 2. Remove all non-alphanumeric characters
 * 3. Use 'unknown' if it does not exist
 */
export const getCreativeTemplateNameForTagging = (adDetails: AdDetails | LightAdDetails): string => {
    if (adDetails.adCreativeMetaData.adCreativeTemplateName) {
        return adDetails.adCreativeMetaData.adCreativeTemplateName.toLowerCase().replace(/[^0-9a-z]/g, '');
    }

    return 'unknown';
};

const tagCreativeId = (adDetails: AdDetails): void => {
    if (adDetails.adCreativeMetaData.adCreativeId) {
        addCsmTag('adrender', 'creativeid:' + adDetails.adCreativeMetaData.adCreativeId);
        setCsaEntity({
            creativeId: adDetails.adCreativeMetaData.adCreativeId,
        });
    }
};

const tagProgramId = (adDetails: AdDetails): void => {
    if (adDetails.adCreativeMetaData.adProgramId) {
        addCsmTag('adrender', 'programid:' + adDetails.adCreativeMetaData.adProgramId);
        setCsaEntity({
            adProgramId: adDetails.adCreativeMetaData.adProgramId,
        });
    }
};

const tagIsCardsFlow = (adDetails: AdDetails): void => {
    if (adDetails.isCardsFlow !== undefined) {
        setCsaEntity({
            isCardsFlow: adDetails.isCardsFlow.toString(),
        });
    }
};
