declare global {
    // eslint-disable-next-line
    interface Window extends WeblabsWindow {}
}

export interface WeblabsWindow {
    renderingWeblabs?: { [key: string]: string };
}

export class Weblab {
    constructor(
        private readonly weblabsWindow: WeblabsWindow,
        private readonly prefix: string,
    ) {}

    isT1 = (): boolean => {
        return this.weblabTreatment() === 't1';
    };

    isT2 = (): boolean => {
        return this.weblabTreatment() === 't2';
    };

    isT3 = (): boolean => {
        return this.weblabTreatment() === 't3';
    };

    isT4 = (): boolean => {
        return this.weblabTreatment() === 't4';
    };

    isT5 = (): boolean => {
        return this.weblabTreatment() === 't5';
    };

    private weblabTreatment = (): string | undefined => {
        return this.weblabsWindow?.renderingWeblabs?.[this.prefix]?.toLowerCase();
    };
}

/*
 * Gonna be used for some general light ads refactorings
 * @link  https://weblab.amazon.com/wl/ADPT_SF_LIGHTADS_REFACTOR_903064
 */
export const ADPT_SF_LIGHTADS_REFACTOR = () => new Weblab(window, 'ADPT_SF_LIGHTADS_REFACTOR_903064');

/*
 * Used for gating IN art event styling
 * @link https://weblab.amazon.com/wl/A2I_HOMEPAGE_CARD_THEMING_360311
 */
export const A2I_HOMEPAGE_CARD_THEMING = () => new Weblab(window, 'A2I_HOMEPAGE_CARD_THEMING_360311');

/*
 * Used for sampling metrics logging
 * @link https://weblab.amazon.com/wl/ADPT_SF_METRICS_LOGGING_1100263
 */
export const ADPT_SF_METRICS_LOGGING = () => new Weblab(window, 'ADPT_SF_METRICS_LOGGING_1100263');

/*
 * Use setInterval to check BTR
 * @link https://weblab.amazon.com/wl/ADPT_SF_BTR_SIMPLE_1107281
 */
export const ADPT_SF_BTR_SIMPLE = () => new Weblab(window, 'ADPT_SF_BTR_SIMPLE_1107281');

/*
 * Use Light Ads pixel firing logic in Safeframe for COD pixel
 * @link https://weblab.amazon.com/wl/ADPT_SF_PIXEL_FIRING_1151992
 */
export const ADPT_SF_PIXEL_FIRING_1151992 = () => new Weblab(window, 'ADPT_SF_PIXEL_FIRING_1151992');

/*
 * Use Light Ads pixel firing logic in Safeframe for BTR, Measurability, and View pixels
 * @link https://weblab.amazon.com/wl/ADPT_SF_BTR_AND_VIEW_PIXEL_FIRING_1201741
 */
export const ADPT_SF_BTR_AND_VIEW_PIXEL_FIRING_1201741 = () =>
    new Weblab(window, 'ADPT_SF_BTR_AND_VIEW_PIXEL_FIRING_1201741');

/**
 * Add focus detection to Light Ads viewability tracking
 * @link https://weblab.amazon.com/wl/ADPT_SF_FOCUS_DETECTION_1175381
 */
export const ADPT_SF_FOCUS_DETECTION_1175381 = () => new Weblab(window, 'ADPT_SF_FOCUS_DETECTION_1175381');

/*
 * Use for triggering sponsored label server side rendering.
 * @link https://weblab.amazon.com/wl/ADPT_SF_SERVER_SIDE_SPONSORED_LABEL_998980
 */
export const ADPT_SF_SPONSORED_LABEL_SERVER_SIDE = () =>
    new Weblab(window, 'ADPT_SF_SERVER_SIDE_SPONSORED_LABEL_998980');

/*
 * Use for triggering sponsored label a11y fixes
 * @link https://weblab.amazon.com/wl/APM_STORES_JPS_JRS_SAFEFRAME_A11Y_SPONSORED_LABEL_1206350
 */
export const APM_STORES_JPS_JRS_SAFEFRAME_A11Y_SPONSORED_LABEL = () =>
    new Weblab(window, 'APM_STORES_JPS_JRS_SAFEFRAME_A11Y_SPONSORED_LABEL_1206350');
