import { ClientMessageToHost } from '../components/messenger.js';
import type { AdDetails } from '../../@types/adCommon.js';
import type { CommonSupportedCommands } from '../host/api-client/CommonSupportedCommands.js';
import type { MobileSupportedCommands } from '../host/api-client/MobileSupportedCommands.js';
import { errorLog } from './commonSf.js';

export type ToHostCommandType = keyof CommonSupportedCommands | keyof MobileSupportedCommands;

export class ClientMessageSender {
    constructor(
        private o: AdDetails,
        private messagePort: MessagePort,
    ) {}

    sendMessage = <T extends (...args: any) => any>(command: ToHostCommandType, data?: Parameters<T>[1]) => {
        const msg: ClientMessageToHost = {
            command: command,
            adDetails: this.o,
            payload: data || {},
        };

        try {
            this.messagePort.postMessage(msg);
        } catch (ex) {
            errorLog('Post Message error', ex as Error);
        }
    };
}
